import React, {useEffect} from "react"
import PropTypes from "prop-types"
import { useData } from '../utils/DataProvider'
import { useInView } from 'react-intersection-observer';
// import { revealText} from "../animations/Animate"
import Section from "./Section"
import "./Hero.scss"

const Hero = ({ data }) =>{
  const [state, setState] = useData()
  const {stripes, title} = data;
  const [ ref, inView ] = useInView({
    rootMargin: "-100px 0px",
    threshold: 0,
  });

  useEffect(() => {
    setState({ ...state, isFixed: !inView});
  }, [inView])

  // DISABLED per client request
  // useEffect(() => {
  //   revealText('.jsHeroText .line__text', 1)
  // }, [])

  return (
    <Section
      color={state.color || 'black'}
      className="hero"
      stripes={stripes}
      forwardedRef={ref}
      data-visible={inView}
      >
      <div className="hero jsInnerHero" >
        <h1 className="title hero__title jsHeroText"
          dangerouslySetInnerHTML={{
            __html: title
          }}
          >
        </h1>
      </div>
    </Section>
)}


Hero.propTypes = {
  data: PropTypes.object
}

export default Hero
